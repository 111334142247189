.gecko-watermark {
  display: none !important;
}

.crypto-main {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  width: 40%;
  border-radius: 14px;
  padding: 10px 20px;
  height: 100%;
  margin-right: 20px;
}

.crypto-main img {
  height: 54px;
  width: 54px;
  border-radius: 50%;
}

.crypto-main {
  height: 100%;
  color: var(--primary-font-color);
}

.crypto-main h1 {
  font-family: 'Inter';
  font: 20px;
  line-height: 28px;
  font-weight: 600;
}

.crypto-main p {
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 500;
  line-height: 22.2px;
  color: var(--dashboard-navbar-font-color);
}

.crypto-chart {
  width: 60%;
  height: 100%;
  background: var(--dashboard-background-color);
  border: 2px solid var(--dashboard-border-color);
  border-radius: 14px;
}

.crypto-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.crypto-infos {
  width: 50%;
  background: var(--dashboard-background-color);
  border: 2px solid var(--dashboard-border-color);
  border-radius: 14px;
  padding: 10px 20px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crypto-bottom {
  display: flex;
  justify-content: space-between;
}

.crypto-infos p {
  font-size: 15px;
  line-height: 22px;
  font-family: 'Inter';
  font-weight: 500;
  color: var(--primary-font-color);
  display: flex;
  justify-content: space-between;
}

.crypto-buy {
  background: var(--dashboard-background-color);
  border: 2px solid var(--dashboard-border-color);
  border-radius: 14px;
  padding: 10px 20px;
  height: 100%;
  width: 60%;
}

.crypto-infos p span {
  color: var(--dashboard-navbar-font-color);
}

.buy-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buy-option {
  width: 33%;
  background-color: var(--dashboard-navbar-color);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  border: 2px solid var(--dashboard-border-color);
}

.crypto-buy h2 {
  color: var(--primary-font-color);
  font-weight: 500;
}

.buy-option div {
  display: flex;
  align-items: center;
}

.buy-option div img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.buy-option h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--primary-font-color);
  font-weight: 500;
}

.buy-option p {
  font-size: 14px;
  color: var(--dashboard-navbar-font-color);
  margin-bottom: 10px;
}

.buy-option a {
  font-size: 14px;
  color: #1e90ff;
  text-decoration: none;
}

.buy-option a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .crypto-top {
    flex-direction: column;
  }

  .crypto-bottom {
    flex-direction: column;
  }

  .crypto-main {
    margin-top: 10px;
    width: 100%;
    margin-right: 0;
  }

  .crypto-chart {
    margin-top: 10px;
    width: 100%;
  }

  .crypto-infos {
    width: 100%;
  }

  .crypto-buy {
    margin-top: 10px;
    width: 100%;
  }

  .buy-option {
    width: 100%;
  }

  .buy-options {
    flex-direction: column;
  }
}
