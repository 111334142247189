.signup-login-button {
  color: rgba(255, 255, 255, 0.3);
  position: relative;
}

.signup-login-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.signup-signup-button {
  position: relative;
}

.signup-signup-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: #1645ee;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.signup-login {
  font-size: 12px;
  display: flex;
  color: white;
  margin: 0;
}

.signup-login a {
  cursor: pointer;
  padding-left: 2%;
  text-decoration: underline;
}

.error-validation {
  display: flex;
  color: rgb(174, 16, 16);
  text-align: left;
  font-size: 14px;
}

.accept-signup {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 10px;
}
