.tutorial-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Permet aux cartes de passer à la ligne suivante */
  gap: 20px; /* Espacement entre les cartes */
  padding: 20px;
  box-sizing: border-box; /* Assure que le padding est inclus dans la largeur totale */
}

.tutorial-content::-webkit-scrollbar {
  width: 6px;
}

.tutorial-content::-webkit-scrollbar-thumb {
  background-color: var(--site-color);
  border-radius: 10px;
}

.tutorial-content::-webkit-scrollbar-track {
  background: transparent;
}

.card-tutorial {
  width: calc(33.333% - 20px); /* Prend 1/3 de la largeur moins le gap */
  display: block;
  background: var(--dashboard-navbar-color);
  padding: 20px;
  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box; /* Assure que le padding est inclus dans la largeur totale */
}

.card-tutorial div {
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  height: 319px;
  position: relative; /* Nécessaire pour le positionnement absolu de l'icône */
  background-size: cover;
}

.card-tutorial h2 {
  font-size: 20px;
  line-height: 28px;
  font-family: 'Poppins';
  font-weight: 600;
  color: var(--primary-font-color);
}

.card-tutorial p {
  font-size: 15px;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-font-color);
}

.card-tutorial div svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
}

@media screen and (max-width: 1200px) {
  .card-tutorial {
    width: calc(50% - 20px); /* Prend 1/2 de la largeur moins le gap */
  }
}

@media screen and (max-width: 800px) {
  .card-tutorial {
    width: 100%; /* Prend toute la largeur */
  }
}

.tutorial-1 div {
  background-image: url('../../../assets/tutorials/tutorial1.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.tutorial-2 div {
  background-image: url('../../../assets/tutorials/tutorial2.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.tutorial-3 div {
  background-image: url('../../../assets/tutorials/tutorial3.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.tutorial-4 div {
  background-image: url('../../../assets/tutorials/tutorial4.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.tutorial-5 div {
  background-image: url('../../../assets/tutorials/tutorial5.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.tutorial-6 div {
  background-image: url('../../../assets/tutorials/tutorial6.webp');
  background-size: cover;
  background-repeat: no-repeat;
}
