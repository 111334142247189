.dashboard {
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  background: var(--dashboard-secondary-background-color);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.dashboard-content {
  height: 100%;
}

.main-page-content {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.gradient-dashboard-1 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 10%;
  left: 15%;
  transform: translate(-50%, -50%);
  width: 75vh;
  height: 75vh;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(15, 48, 165, 0.378) 0%, rgba(255, 255, 255, 0) 70%);
  overflow: hidden;
}

.gradient-dashboard-2 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 90%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 75vh;
  height: 75vh;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(15, 48, 165, 0.378) 0%, rgba(255, 255, 255, 0) 70%);
  overflow: hidden;
}

.gradient-dashboard-3 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 75vh;
  height: 75vh;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(15, 48, 165, 0.378) 0%, rgba(255, 255, 255, 0) 70%);
  overflow: hidden;
}

.gradient-dashboard-4 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 10%;
  left: 95%;
  transform: translate(-50%, -50%);
  width: 75vh;
  height: 75vh;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(15, 48, 165, 0.378) 0%, rgba(255, 255, 255, 0) 70%);
  overflow: hidden;
}

@media screen and (max-width: 1250px) {
  .main-page-content {
    flex-direction: column;
  }
}
