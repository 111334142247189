.faq {
  width: 100%;
  padding: 50px 10%;
  z-index: 1;
}

.accordion-list {
  padding-top: 5%;
}

.faq-title {
  text-align: center;
  width: 50%;
  left: 25%;
}

.faq-title h1 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 48px;
  color: var(--primary-font-color);
}

.faq-title p {
  font-family: 'Roboto';
  font-size: 18px;
  color: var(--secondary-font-color);
}

.bar-separate {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
}

.arrow-down-unselected svg {
  transition: all 0.5s;
  transform: rotate(0);
  font-size: 24px;
}

.arrow-down-selected svg {
  transition: all 0.5s;
  transform: rotate(180deg);
  font-size: 24px;
}

.item {
  margin-bottom: 5px;
  padding: 10px 20px;
  border-bottom: 1px solid #9a9a9a8e;
}

.item .title-item {
  color: var(--primary-font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: 'Poppins';
}

.item .title-item h2 {
  font-weight: 400;
  font-size: 22px;
}

.title h2 {
  font-size: 20px;
}

.content {
  color: var(--secondary-font-color);
  font-family: 'Poppins';
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  background: transparent;
}

.content.show {
  height: auto;
  max-height: 999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.title span {
  font-size: 30px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .faq-title {
    width: 100%;
    left: 0;
  }

  .title h2 {
    font-size: 16px;
  }

  .item .title-item h2 {
    font-size: 16px;
  }
}
