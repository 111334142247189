.error-page {
  background: var(--background-color);
  color: var(--primary-font-color);
  font-family: 'Poppins';
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.error-page::before {
  z-index: 0;
  content: '';
  position: absolute;
  top: -4%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: var(--app-gradient);
}

.error-container {
  display: inline;
  text-align: center;
}

.error-container h1 {
  font-size: 80px;
  padding: 0;
  margin: 0;
}

.error-container p {
  font-size: 20px;
}

.error-container a {
  cursor: pointer;
  text-decoration: underline;
}
