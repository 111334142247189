.wallets-dashboard {
  padding: 25px 20px 25px 0;
  width: 83%;
  height: 100%;
  max-height: 100%;
  font-family: "Inter";
}

.wallets-content {
  height: 100%;
  width: 100%;
  border-radius: 24px;
}

.wallets-container {
  display: flex;
  justify-content: space-between;
}

.wallet {
  width: 100%;
  height: 428px;
  display: block !important;
  max-height: 428px;
  padding: 0px 10px;
}

.wallet img {
  width: 200px;
}

.wallet-container-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet button {
  background: #1645ee;
  border-radius: 100px;
  border: transparent;
  height: 52px;
  color: white;
  font-family: "Inter";
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.wallet-title {
  font-size: 25px;
  color: white;
  font-weight: bold;
}
