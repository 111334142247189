[data-theme='dark'] {
  --background-color: #030c2b;
  --secondary-background-color: rgba(22, 69, 238, 0.1);
  --third-background-color: rgba(255, 255, 255, 0.1);
  --primary-font-color: #fff;
  --secondary-font-color: rgba(255, 255, 255, 0.7);
  --third-font-color: #000;
  --fourth-font-color: rgba(255, 255, 255, 0.8);
  --button-background-color: #fff;
  --dashboard-background-color: #091439;
  --dashboard-secondary-background-color: #030c2b;
  --dashboard-border-color: #151f42;
  --dashboard-navbar-color: #091439;
  --dashboard-opacity: 0.4;
  --dashboard-font-color: rgba(255, 255, 255, 0.4);
  --dashboard-navbar-active-color: rgb(255, 255, 255);
  --dashboard-navbar-font-color: rgba(255, 255, 255, 0.4);
  --dashboard-even-color: rgba(22, 69, 238, 0.1);
  --app-gradient: radial-gradient(circle at center, rgba(22, 69, 238, 0.8) 0%, rgba(255, 255, 255, 0) 70%);
  --site-color: #1645ee;
}

[data-theme='light'] {
  --background-color: #fff;
  --secondary-background-color: rgba(170, 170, 170, 0.12);
  --third-background-color: rgba(0, 0, 0, 0.1);
  --primary-font-color: #000;
  --secondary-font-color: rgba(0, 0, 0, 0.7);
  --third-font-color: #fff;
  --fourth-font-color: rgba(0, 0, 0, 0.8);
  --button-background-color: #1645ee;
  --dashboard-background-color: #ffffff;
  --dashboard-secondary-background-color: #ffffff;
  --dashboard-navbar-color: rgba(242, 244, 251, 1);
  --dashboard-border-color: #9e9e9e30;
  --dashboard-opacity: 0.9;
  --dashboard-font-color: rgba(0, 0, 0, 0.6);
  --dashboard-navbar-active-color: rgb(255, 255, 255);
  --dashboard-navbar-font-color: rgba(0, 0, 0, 0.6);
  --dashboard-even-color: rgba(242, 244, 251, 1);
  --app-gradient: #fff;
  --site-color: #1645ee;
}

* {
  box-sizing: border-box;
  position: relative;
  scroll-behavior: smooth;
}

.toaster-app {
  position: fixed !important;
}

html {
  width: 100%;
}

.app {
  background: var(--background-color);
  background-size: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.app::after {
  z-index: 0;
  content: '';
  position: absolute;
  top: -4%;
  left: 10%;
  transform: translate(-50%, -50%);
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: var(--app-gradient);
}

.app::before {
  z-index: 0;
  content: '';
  position: absolute;
  top: 25%;
  left: 95%;
  transform: translate(-50%, -50%);
  width: 1500px;
  height: 1500px;
  border-radius: 50%;
  background: var(--app-gradient);
}

.gradient-1 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150vh;
  height: 150vh;
  border-radius: 50%;
  background: var(--app-gradient);
  overflow: hidden;
}

.gradient-2 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150vh;
  height: 150vh;
  border-radius: 50%;
  background: var(--app-gradient);
  overflow: hidden;
}

.gradient-4 {
  z-index: 0;
  content: '';
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 200vh;
  height: 200vh;
  border-radius: 50%;
  background: var(--app-gradient);
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .app::after {
    width: 1300px;
    height: 1300px;
    top: 0%;
  }

  .app::before {
    width: 1300px;
    height: 1300px;
  }
}

@media only screen and (max-width: 600px) {
  .app::after {
    width: 800px;
    height: 800px;
    top: 0%;
  }

  .app::before {
    width: 800px;
    height: 800px;
  }
}
