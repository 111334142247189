.advantages {
  font-family: 'Poppins', 'Roboto' !important;
  width: 100%;
  padding: 15px 0px;
  justify-content: center;
  z-index: 1;
  display: flex;
}

.advantages-without {
  margin-right: 100px;
  padding: 10px;
  border-radius: 8px;
  width: 450px;
  background: var(--secondary-background-color);
}

.advantages-with {
  padding: 10px;
  border-radius: 8px;
  width: 450px;
  background: var(--site-color);
}

.advantages-without p {
  text-align: center;
  color: var(--primary-font-color);
  padding: 10px 25px;
  font-size: 20px;
  font-weight: bold;
}

.advantages-with p {
  text-align: center;
  color: white;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: bold;
}

.advantages-without ul {
  color: var(--primary-font-color);
  list-style: none;
  font-size: 14px;
}

.advantages-with ul {
  list-style: none;
  font-size: 14px;
  color: white;
}

.advantages-without ul li,
.advantages-with ul li {
  display: flex;
  justify-content: left;
  padding: 5px;
}

.advantages-without ul li span,
.advantages-with ul li span {
  font-size: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 1400px) {
  .advantages-with {
    width: 450px;
  }

  .advantages-without {
    width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .advantages {
    display: inline-block;
    padding: 15px 5%;
  }

  .advantages-with {
    width: 100%;
  }

  .advantages-without {
    width: 100%;
    margin-bottom: 30px;
  }

  .advantages-with ul {
    padding-left: 15px;
  }

  .advantages-without ul {
    padding-left: 15px;
  }
}
