.market-cap-dashboard {
  padding: 25px 20px 25px 0;
  width: 83%;
  height: 100%;
  max-height: 100%;
  font-family: "Inter";
}

.market-cap-title {
  height: 3%;
  margin: 0 0 1% 0;
  font-size: 24px;
  color: white;
}

.market-cap-container {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.table-title {
  width: 100%;
  color: white;
  font-size: 20px;
  font-family: "Inter";
}

.crypto-table thead {
  color: #666d80;
  font-family: "Inter";
  text-align: left;
  position: sticky;
  top: 0;
  background: #272835;
  z-index: 1;
}

.crypto-table thead tr th {
  padding: 10px;
}

.crypto-table {
  padding: 20px;
  width: 100%;
  height: 70%; /* 70% de la hauteur parente */
  max-height: 70%;
  background: #272835;
  border-radius: 20px;
}

.market-cap-description {
  color: white;
  height: 30%;
}

.rank-element {
  text-align: left;
}

.market-cap-content {
  overflow-y: auto; /* Activation du défilement vertical si nécessaire */
  scrollbar-width: thin;
  scrollbar-color: white #272835;
}
