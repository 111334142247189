.contact-form {
  width: 100%;
  padding: 100px 10%;
  z-index: 1;
  background: var(--secondary-background-color);
}

.contact-title {
  text-align: center;
}

.contact-title h1 {
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 48px;
  color: var(--primary-font-color);
  text-wrap: nowrap;
}

.contact-title p {
  font-family: 'Roboto';
  font-size: 18px;
  color: var(--secondary-font-color);
}

.form-contact-email div {
  width: 100%;
}

.contact-form-content {
  padding-top: 5%;
}

.text-area-content {
  padding-top: 0.5%;
  padding-left: 0.5%;
}

.contact-form-email-content {
  padding-left: 1%;
  background: var(--third-background-color);
  color: var(--secondary-font-color);
  width: 99.5% !important;
  font-size: 16px;
  height: 200px;
  border-color: transparent;
  border-radius: 8px;
  font-family: 'Roboto';
  text-align: left;
  vertical-align: top;
  resize: none;
}

.form-contact-email input {
  background: var(--third-background-color);
  color: var(--secondary-font-color);
  font-family: 'Roboto';
  font-size: 16px;
  margin: 0.5%;
  padding: 15px;
  border-radius: 8px;
  border-color: transparent;
  width: 49%;
  outline: none;
}

.contact-form-email-content {
  padding-top: 10px;
  padding-left: 15px;
  outline: none;
}

.contact-form button {
  background: var(--button-background-color);
  color: var(--third-font-color);
  cursor: pointer;
  padding-right: 1%;
  margin-top: 20px;
  font-family: 'Roboto';
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-color: transparent;
  border-radius: 50px;
}

.arrow-right-form {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .contact-title h1 {
    font-size: 40px;
  }
}
