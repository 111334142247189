.top-navbar-dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.navbar-title {
  color: var(--primary-font-color);
  font-size: 24px;
  font-family: 'Inter';
  font-weight: 600;
  transition: color 0.3s;
}

.top-navbar-button {
  display: flex;
}

.icon-container-mobile {
  display: flex;
  align-items: center;
}

.icon-container-mobile img {
  width: 24px;
  height: 24px;
}

.top-navbar-icon {
  color: var(--primary-font-color);
  cursor: pointer;
  font-size: 24px;
  padding: 0px 7.5px;
}

@media screen and (max-width: 1250px) {
  .top-navbar-button {
    display: none;
  }
}
