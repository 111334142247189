.footer {
  width: 100%;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  z-index: 1;
  background: var(--primary-background-color);
}

.list-links {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.list-footer {
  display: inline-block;
}

.list-footer p {
  color: var(--primary-font-color);
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
}

.list-footer ul {
  font-family: 'Roboto';
  list-style: none;
  padding: 0;
}

.list-footer ul li {
  color: var(--secondary-font-color);
  font-family: 'Roboto';
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}

.social-media p {
  font-family: 'Roboto';
  color: var(--primary-font-color);
}

.disclaimer {
  padding-top: 2%;
  color: var(--primary-font-color);
  font-size: 8px;
  text-align: center;
  margin: 0;
}
