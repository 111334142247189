.login {
  background: var(--background-color);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.content-img {
  width: 50%;
}

.content-login {
  width: 50%;
  padding: 5% 7.5%;
}

.content-image {
  width: 50%;
}

.background-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.login-title {
  margin-bottom: 50px;
}

.login-title h1 {
  font-weight: 600;
  line-height: 30px;
  color: var(--primary-font-color);
  font-family: 'Poppins';
  font-size: 36.5px;
}

.login-title p {
  font-family: 'Inter';
  color: var(--secondary-font-color);
  font-size: 16px;
}

.login-form form p {
  color: var(--primary-font-color);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
}

.login-form form p span {
  color: #ff4026;
}

.login-form form input {
  color: var(--primary-font-color);
  width: 100%;
  background: transparent;
  height: 57px;
  border-radius: 16px;
  border: 1px solid var(--third-background-color);
  padding-left: 20px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  outline: none;
}

.login-form form button {
  margin-top: 25px;
  width: 100%;
  height: 57px;
  background: var(--site-color);
  border-radius: 16px;
  color: white;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.stay-connected {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--primary-font-color);
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
}

.stay-connected label {
  display: flex;
  cursor: pointer;
}

.stay-connected a {
  cursor: pointer;
}

.password-box {
  display: flex;
}

.password-box svg {
  font-size: 25px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  color: var(--primary-font-color);
}

.stay-connected label input {
  margin: 0 10px 0 0;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.create-account {
  display: flex;
  align-items: center;
}

.create-account p {
  color: var(--secondary-font-color);
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
}

.create-account a {
  color: var(--primary-font-color);
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  cursor: pointer;
  padding-left: 5px;
}

.content-img div {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-align: center;
  cursor: pointer;
}

.content-img-mobile div {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.content-img-mobile {
  display: none;
}

.content-img div a,
.content-img-mobile div a {
  font-size: 42px;
  font-family: 'Inter';
  font-weight: 600;
  color: white;
}

.content-img-mobile div a {
  font-size: 28px !important;
}

@media screen and (max-width: 1200px) {
  .content-login {
    width: 100%;
    padding: 10%;
  }

  .content-img {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .content-img-mobile {
    display: flex;
    width: 100%;
    height: 75px;
    overflow: hidden;
  }

  .content-img-mobile div {
    transform: translate(-50%, 35%);
  }

  .login {
    display: block;
  }

  .login-title h1 {
    text-wrap: nowrap;
    font-size: 26px;
  }
}
