.subscription {
  width: 100%;
  padding: 0px 10%;
  z-index: 1;
}

.subscription-title {
  text-align: center;
}

.subscription-title h1 {
  font-size: 48px;
  color: var(--primary-font-color);
  font-family: 'Poppins';
  font-weight: 600;
}

.subscription-title p {
  color: var(--secondary-font-color);
  font-size: 18px;
  width: 50%;
  left: 25%;
}

.logo-subscription {
  font-size: 60px;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
}

.subscription-card {
  padding: 0px 25px;
  padding-top: 5%;
  width: 100%;
}

.subscription-card-popular {
  padding: 0px 25px;
  padding-top: 5%;
  width: 100%;
}

.card-subscription {
  padding: 50px 40px;
  border-radius: 40px;
  width: 100%;
  text-align: left;
}

.card-subscription-popular {
  padding: 50px 40px 30px 40px;
  border-radius: 40px;
  width: 100%;
  text-align: left;
  position: relative;
}

.card-subscription-popular .get-started {
  margin-top: 70px;
}

.card-subscription {
  background: var(--secondary-background-color);
}

.card-subscription-popular {
  background: var(--site-color);
  border: 4px solid rgba(22, 69, 238, 1);
}
.card-subscription p,
.card-subscription-popular p {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}

.card-subscription p {
  color: var(--primary-font-color);
}

.card-subscription h1,
.card-subscription-popular h1 {
  margin: 0;
  padding: 0;
}

.card-description {
  padding-top: 6%;
  font-family: 'Roboto';
  color: var(--secondary-font-color);
}

.card-header {
  display: flex;
  align-items: center;
}

.header-text {
  width: 100%;
  text-align: center;
}

.subscription-card-popular .header-text h1 {
  color: white;
  font-size: 24px;
  font-family: 'Poppins';
}

.subscription-card .header-text h1 {
  color: var(--primary-font-color);
  font-size: 24px;
  font-family: 'Poppins';
}

.header-text p {
  font-family: 'Roboto';
}

.card-price {
  padding: 10px 0px;
  text-align: center;
  align-items: baseline;
  font-weight: 600;
}

.subscription-card .card-price h1 {
  font-size: 48px;
  font-family: 'Poppins';
  color: var(--primary-font-color);
}

.subscription-card-popular .card-price h1 {
  color: white;
  font-size: 48px;
  font-family: 'Poppins';
}

.subscription-card .card-price p {
  font-size: 16px;
  font-family: 'Roboto';
  color: var(--secondary-font-color);
}

.subscription-card-popular .card-price p {
  font-size: 16px;
  font-family: 'Roboto';
  color: rgba(255, 255, 255, 0.7);
}

.card-subscription-popular .get-started {
  background: white;
  color: black;
}

.get-started {
  text-decoration: none;
  color: var(--third-font-color);
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto';
  font-weight: bold;
  width: 100%;
  padding: 18px 15px;
  border-radius: 50px;
  background: var(--primary-font-color);
  overflow: hidden;
}

.card-list {
  color: var(--primary-font-color);
  text-align: left;
}

.cards-subscription {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-subscription ul li {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 5px 0px;
}

.card-subscription ul,
.card-subscription-popular ul {
  padding: 0;
}

.card-subscription-popular ul li {
  padding: 5px 0px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.card-subscription ul li p {
  padding-left: 10px;
  font-size: 16px;
  color: var(--secondary-font-color);
}

.card-subscription-popular ul li p {
  padding-left: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.card-subscription a {
  cursor: pointer;
}

.card-subscription-popular a {
  cursor: pointer;
}

.popular-logo {
  position: absolute;
  top: -30px;
  right: -20px;
  padding: 10px 25px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #ffffff32;
  border-radius: 10px;
}

.card-subscription-popular .list-title {
  color: #fff;
}

.list-title {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: bold;
}

.check-logo {
  font-size: 20px;
  background: rgba(22, 69, 238, 1);
  padding: 2px;
  border-radius: 100%;
  color: white;
}

.uncheck-logo {
  font-size: 20px;
  background: var(--secondary-background-color);
  color: black;
  padding: 2px;
  border-radius: 100%;
}

.button-filter {
  width: 100%;
  text-align: center;
}

.button-filter button {
  transition: all 0.3s ease;
  width: 75px;
  height: 40px;
  color: var(--third-font-color);
  background-color: var(--primary-font-color);
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: bold;
}

.button-year {
  margin-left: 10px;
}

.active-button {
  width: 150px !important;
  color: white !important;
  background-color: rgba(22, 69, 238, 1) !important;
}

/*.button-container-24h {
  width: 100%;
  display: flex;
  padding-top: 40px;
  justify-content: center;
  text-align: center;
}

.H24-button {
  font-family: "Roboto";
  font-weight: bold;
  padding: 20px 35px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  border: 1px solid white;
  background-color: rgba(22, 69, 238, 1);
}*/

@media only screen and (max-width: 1600px) {
  .popular-logo {
    right: -20px;
    padding: 10px 17px;
  }
}

@media only screen and (max-width: 1400px) {
  .popular-logo {
    display: none;
  }

  .image-card {
    margin: 25px 0px 0px 0px;
  }

  .subscription {
    padding: 0px;
  }

  .card p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .subscription-title p {
    width: 100%;
    left: 0;
    padding: 0px 30px;
  }

  .subscription-card-popular {
    width: 80%;
  }

  .subscription-card {
    width: 80%;
  }

  .subscription-title h1 {
    font-size: 44px;
  }

  .cards-subscription {
    display: inline-block;
    width: 100%;
    left: 10%;
  }

  .cards-subscription .swiper-pagination {
    left: -10% !important;
  }

  .popular-logo {
    display: none;
  }

  .subscription-card {
    padding: 5% 0%;
  }

  .subscription-card-popular {
    padding: 5% 0%;
  }

  .swiper-button-prev {
    color: var(--primary-font-color) !important;
  }

  .swiper-button-next {
    color: var(--primary-font-color) !important;
    left: 71%;
    right: 0;
  }

  .swiper-button-next::after {
    font-size: 30px;
  }

  .swiper-button-prev::after {
    font-size: 30px;
  }
}
