.sidebar-dashboard {
  width: 250px;
  height: 100%;
  font-family: 'Inter';
  border-right: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-navbar-color);
  padding: 30px 24px 30px 24px;
}

.sidebar-dashboard-content {
  height: 100%;
  background: var(--dashboard-background-color);
}

.sidebar-dashboard-mobile {
  display: none;
  width: 100%;
  height: 77px;
  background: var(--dashboard-navbar-color);
}

.sidebar-logo {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.sidebar-logo .logo-coinfinder {
  margin: 0;
}

.sidebar-buttons {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  color: var(--dashboard-navbar-font-color);
  margin-top: 25px;
}

.sidebar-buttons ul {
  list-style: none;
  padding: 0;
}

.sidebar-buttons ul li {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 12px 14px 16px;
  cursor: pointer;
  border-radius: 14px;
  background: transparent;
  justify-content: left;
}

.sidebar-buttons p {
  padding-left: 10px;
}

.sidebar-buttons ul li.active {
  background: var(--site-color);
  color: var(--dashboard-navbar-active-color);
}

.sidebar-buttons ul li.active svg {
  color: white;
  opacity: 1;
}

.sidebar-buttons ul li svg {
  margin-right: 16px;
  color: var(--primary-font-color);
  opacity: var(--dashboard-opacity);
}

.select-sidebar-mobile {
  padding: 10px 20px;
  font-size: 35px;
  display: flex;
  color: var(--primary-font-color);
  cursor: pointer;
}

.icon-container-mobile {
  padding: 10px 20px;
}

.sidebar-dashboard-mobile-content {
  right: 0;
  position: absolute;
  width: 300px;
  height: 100%;
  background: var(--dashboard-navbar-color);
  z-index: 99;
}

.sidebar-dashboard-mobile-content ul {
  list-style: none;
  color: var(--primary-font-color);
  font-family: 'Poppins';
  margin: 0;
}

.sidebar-dashboard-mobile-content ul li {
  font-size: 16px;
  display: flex;
  height: 60px;
  width: 100%;
}

.sidebar-dashboard-mobile-content ul li svg {
  margin-right: 15px;
}

.sidebar-dashboard-mobile-content {
  transition: all 0.3s ease;
  transform: translateX(100%);
}

.sidebar-dashboard-mobile-content.active {
  transform: translateX(0);
}

.button-manager-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.button-manager-mobile span {
  font-size: 28px;
  padding-right: 10px;
}

@media screen and (max-width: 1250px) {
  .sidebar-dashboard {
    display: none;
  }

  .sidebar-dashboard-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .sidebar-dashboard-mobile-content {
    width: 100%;
  }
}
