.middle-content {
  z-index: 1;
  width: 100%;
  padding: 60px 10%;
}

.middle-content-content {
  overflow: hidden;
  border-radius: 30px;
  display: flex;
  background: rgba(22, 69, 238, 0.1);
}

.content-image {
  height: 608px;
  margin-left: 10%;
}

.content-image img {
  border-radius: 0px 30px;
  height: 100%;
}

.content-text {
  padding-top: 8%;
  text-align: left;
  padding-left: 5%;
}

.content-text h1 {
  line-height: 60px;
  font-weight: 600;
  font-size: 48px;
  font-family: 'Poppins';
  color: var(--primary-font-color);
}

.content-text-mobile {
  display: none;
}

.content-text p {
  font-size: 18px;
  font-family: 'Roboto';
  color: var(--secondary-font-color);
}

.content-image-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .middle-content {
    padding: 60px 0px;
  }

  .middle-content-content {
    border-radius: 0px;
  }

  .content-text {
    display: none;
  }

  .content-image {
    display: none;
  }

  .content-image-mobile {
    display: block;
  }

  .content-image-mobile div img {
    width: 202px;
    height: 245px;
  }

  .content-image-mobile div {
    display: flex;
  }

  .content-mobile p {
    padding-left: 10%;
    color: var(--secondary-font-color);
    font-family: 'Poppins';
    font-size: 14px;
  }

  .content-image-mobile h1 {
    font-weight: 600;
    text-wrap: nowrap;
    font-size: 24px;
    font-family: 'Poppins';
    padding: 0% 3%;
    color: var(--primary-font-color);
  }
}
