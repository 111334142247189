.coinspy-content {
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  margin-bottom: 20px;
  border-radius: 14px;
}

.coinspy-text {
  flex: 1;
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  margin-bottom: 20px;
  border-radius: 14px;
  max-height: 30%;
}

.coinspy-content table {
  border-collapse: collapse;
  width: 100%;
}
.coinspy-content h2 {
  font-family: 'Poppins';
  margin: 0;
  padding: 15px 20px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-font-color);
  transition: color 0.3s;
}

.coinspy-content table thead {
  font-size: 14px;
  font-family: 'Inter';
  color: var(--dashboard-font-color);
}

.coinspy-content table thead th {
  position: sticky;
  top: 0;
  background: var(--dashboard-background-color);
  z-index: 1;
  padding: 10px;
}

.coinspy-content table thead th::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--dashboard-border-color);
}

.coinspy-content table thead tr th,
.coinspy-content table tbody tr td {
  width: 15%;
  padding: 10px;
  text-align: left;
}

.coinspy-content table tbody tr td img {
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.table-container-coinspy {
  padding: 0px 20px;
  flex-grow: 1;
  max-height: 500px;
  overflow-y: auto;
}

.table-container-coinspy::-webkit-scrollbar {
  width: 6px;
}

.table-container-coinspy::-webkit-scrollbar-thumb {
  background-color: var(--site-color);
  border-radius: 10px;
}

.table-container-coinspy::-webkit-scrollbar-track {
  background: transparent;
}

.coinspy-content table tbody tr td {
  font-size: 14px;
  font-family: 'Inter';
  color: var(--primary-font-color);
}

.coinspy-content table tbody tr:nth-child(even) {
  background: var(--dashboard-even-color);
}

.coinspy-content table thead tr th {
  font-weight: 400;
}

.coinspy-text h2 {
  font-family: 'Poppins';
  margin: 0;
  padding: 15px 20px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-font-color);
  transition: color 0.3s;
}

.coinspy-text p {
  font-family: 'Poppins';
  margin: 0;
  padding: 5px 20px;
  line-height: 22px;
  font-size: 15px;
  color: var(--secondary-font-color) !important;
}

.symbol-content-coinspy {
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .coinspy-text {
    max-height: 100%;
  }
}
