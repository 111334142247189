.accordion-main {
  padding: 20px 0px;
}

.accordion-button {
  cursor: pointer;
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: transparent;
}

.accordion-arrow {
  color: #fff;
  font-size: 24px;
}

.accordion-title {
  font-family: "Inter";
  font-size: 20px;
  color: #fff;
}

.accordion {
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}

.accordion div {
  padding-top: 3%;
}

.accordion-open {
  display: inline;
  height: auto;
  max-height: 999;
}

.accordion-close {
  display: none;
  max-height: 0;
}
