.landing-page-tutorial {
  padding: 0px 20px 20px 20px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Inter';
}

.landing-page-tutorial h1,
.landing-page-tutorial h2,
.landing-page-tutorial h3 {
  color: var(--primary-font-color);
}

.landing-page-tutorial p {
  color: var(--fourth-font-color);
}

.landing-page-tutorial ul,
.landing-page-tutorial ol {
  color: var(--fourth-font-color);
}
