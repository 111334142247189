.expertise {
  font-family: 'Poppins', 'Roboto' !important;
  width: 100%;
  padding: 15px 10%;
  z-index: 1;
}

.title-expertise {
  text-align: center;
  font-size: 24px;
  color: var(--primary-font-color);
}

.title-expertise h1 {
  font-family: 'Poppins';
  font-weight: 600;
  text-wrap: nowrap;
}

.title-expertise p {
  color: var(--secondary-font-color);
  font-size: 16px;
}

.content-expertise {
  padding-top: 4%;
  display: flex;
  justify-content: space-between;
}

.card {
  background: var(--secondary-background-color);
  padding: 0px 40px;
  border-radius: 40px;
  width: 30%;
  text-align: center;
}

.card div {
  width: 100%;
  text-align: center;
}

.image-card {
  margin: 25px 0px;
  padding: 20px;
  font-size: 96px;
  border-radius: 25px;
  color: white;
  background: rgba(22, 69, 238, 1);
}

.card h2 {
  font-family: 'Poppins';
  font-weight: 600;
  background-color: transparent;
  font-size: 22px;
  color: var(--primary-font-color);
}

.card p {
  background-color: transparent;
  font-size: 16px;
  color: var(--secondary-font-color);
}

.content-expertise-swiper {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .content-expertise {
    display: none;
  }

  .content-expertise .card {
    width: 100%;
    margin: 40px 0px;
  }

  .content-expertise-swiper {
    display: block !important;
  }

  .card {
    width: 100%;
  }
}
