.navbar-index {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 10%;
  font-family: 'Roboto';
}

.logo-coinfinder {
  color: var(--primary-font-color);
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 600;
  transition: color 0.3s;
}

.app-logo {
  top: 3px;
  height: 45px;
}

.logo {
  font-weight: normal;
  width: 150px;
  cursor: pointer;
}

.navbar-index ul {
  flex: 1;
  list-style: none;
  text-align: right;
}

.navbar-index ul li {
  display: inline-block;
  margin: 10px 20px;
  font-weight: normal;
  font-size: 18px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container img {
  width: 24px;
  height: 24px;
}

.logo-coinfinder {
  padding-left: 5px;
}

.connect-button {
  position: relative;
  font-weight: bold;
  border: 2px solid var(--fourth-font-color);
  color: var(--fourth-font-color);
  padding: 10px 35px;
  border-radius: 100px;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}
.connect-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--fourth-font-color);
  transition: left 0.3s;
  z-index: -1;
}

.disconnect-button {
  position: relative;
  font-weight: bold;
  border: 2px solid var(--fourth-font-color);
  color: var(--fourth-font-color);
  padding: 10px 35px;
  border-radius: 100px;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

.disconnect-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--fourth-font-color);
  transition: left 0.3s;
  z-index: -1;
}

.dashboard-button {
  cursor: pointer;
}

.connect-button:hover::before {
  left: 0;
}

.connect-button:hover {
  color: var(--third-font-color);
}

.disconnect-button:hover::before {
  left: 0;
}

.disconnect-button:hover {
  color: var(--third-font-color);
}

ul li a {
  text-decoration: none;
  color: var(--fourth-font-color);
  transition: color 0.3s;
}

.list-mobile li a {
  color: var(--primary-font-color);
}

.mobile-menu {
  background: rgb(139 139 139 / 25%);
  border-radius: 8px;
  font-size: 35px !important;
  padding: 5px;
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: var(--primary-font-color);
}

.dark-mode {
  font-size: 24px;
  margin-left: 15px;
  cursor: pointer;
  color: var(--fourth-font-color);
}

.dark-mode-mobile {
  font-size: 24px;
  cursor: pointer;
  color: var(--primary-font-color);
}

.navbar-responsive {
  transition: all 0.3s ease;
  right: -310px;
  position: fixed;
  z-index: 999;
  top: 0;
  height: 100vh;
  width: 300px;
  background-color: var(--background-color);
  box-shadow: -5px 0 10px rgba(0, 0, 0, 1);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.navbar-responsive.active {
  right: 0px;
}

.close-button-navbar {
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 40px;
  padding-left: 35px;
  font-size: 36px;
  color: var(--primary-font-color);
}

.close-button-navbar .close-button {
  padding: 0;
  margin: 0;
}
.navbar-responsive ul {
  font-family: 'Poppins';
  color: var(--primary-font-color);
  margin: 0;

  list-style: none;
}

.navbar-responsive ul li {
  height: 60px;
  width: 100%;
}

.disconnect-button-mobile {
  color: var(--primary-font-color);
  text-decoration: none;
  padding-left: 40px;
  font-family: 'Poppins';
}

.connect-button-mobile {
  color: var(--primary-font-color);
  text-decoration: none;
  padding-left: 40px;
  font-family: 'Poppins';
}

.theme-select {
  padding-left: 40px;
  padding-top: 30px;
}
ul li a:hover {
  color: grey;
}

@media only screen and (max-width: 1250px) {
  .hidden-on-mobile {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .navbar-index {
    padding: 0px 10%;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-responsive {
    width: 100%;
    right: -110%;
  }
}
