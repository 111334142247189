.logos {
  padding: 60px 0px;
  overflow: hidden;
  font-family: 'Poppins', 'Roboto';
  font-size: 20px;
  display: flex;
}

.logos::before,
.logos::after {
  position: absolute;
  top: 0;
  height: 100%;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.logo-slide {
  display: flex;
  white-space: nowrap;
  text-align: center;
  animation: slide 20s infinite linear;
}

.logo-slide img {
  height: 40px;
}

.logo-slide div {
  margin: 0 36.5px;
  align-items: center;
  display: flex;
}

.logo-slide p {
  padding-left: 15%;
  color: var(--primary-font-color);
}
