.administration {
  padding: 3% 10%;
  width: 100%;
  min-height: 100vh;
}

.container {
  height: 100%;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.container .title {
  font-size: 36px;
}

.title-cryptos-low-caps {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.description {
  font-size: 12px;
  color: #5a5a5a;
}

.cryptos-low-caps-area {
  width: 50%;
  height: 150px;
}

.cryptos-low-caps-area {
  font-family: "Poppins";
  background-color: #dddddd;
  color: #666666;
  padding: 1em;
  border-radius: 10px;
  border: 2px solid transparent;
  outline: none;
  cursor: text;
  resize: none;
}

.submit-low-caps {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
  color: white;
  background-color: rgb(17 24 39);
  cursor: pointer;
}
