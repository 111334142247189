.faq-dashboard {
  padding: 25px 0px;
  max-height: 90%;
  overflow-y: auto;
  width: 83%;
  height: 99%;
  font-family: "Inter";
  color: white;
  scrollbar-width: thin;
  scrollbar-color: white #272835;
}

.accordion-list-faq-dashboard {
  padding: 0;
}

.accordion-list-faq-dashboard .item {
  color: #dfe1e7;
  font-family: "Inter";
}

.accordion-list-faq-dashboard .item .title {
  background: #36394a;
  border-radius: 32px;
  padding: 5px 20px;
}

.accordion-list-faq-dashboard .item .content {
  font-size: 14px !important;
}

.accordion-list-faq-dashboard .item .title.show {
  background: #1645ee;
}

.accordion-list-faq-dashboard .item .title h2 {
  font-size: 16px !important;
}
