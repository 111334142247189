.e-book-page {
  padding: 0px 20px 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.e-book-content {
  flex: 1;
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  margin-bottom: 20px;
  border-radius: 14px;
}

.e-book-content h2 {
  font-family: 'Poppins';
  margin: 0;
  padding: 15px 20px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-font-color);
  transition: color 0.3s;
}

.e-book-content p {
  font-family: 'Poppins';
  margin: 0;
  padding: 5px 20px;
  line-height: 22px;
  font-size: 15px;
  color: var(--secondary-font-color) !important;
}

.e-book-button {
  padding: 15px 20px;
  display: flex;
}

.e-book-button div {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(80, 118, 254, 1);
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  padding-right: 20px;
}

.e-book-button div a {
  padding-left: 10px;
}

.e-book-button div svg {
  font-size: 16px;
}

.e-book-img {
  padding: 20px 20px;
}

.view-ebook svg {
  font-size: 20px !important;
}

.e-book-img img {
  border-radius: 12px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
