.hero {
  z-index: 1;
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  padding: 60px 10%;
}

.text-content {
  padding-top: 3%;
  width: 100%;
}

.underline-description {
  font-size: 80px !important;
}

.text-content .title-hero {
  transition: color 0.3s;
  font-family: 'Poppins', 'Roboto';
  font-size: 110px;
  color: var(--primary-font-color);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 85px;
  margin-bottom: 10px;
}

.text-content div .description {
  transition: color 0.3s;
  color: var(--secondary-font-color);
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  font-size: 18px;
}

.discover svg {
  transition: color 0.3s;
}

.discover {
  transition: color 0.3s;
  color: var(--third-font-color);
  background-color: var(--button-background-color);
  padding: 10px 30px;
  border-radius: 100px;
  text-decoration: none;
  font-weight: bold;
}

.discover-content {
  padding-top: 5%;
}

.arrow-right {
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  text-align: center;
  background-color: var(--button-background-color);
  color: var(--third-font-color);
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .hero img {
    display: none;
  }

  .text-content {
    text-align: center;
  }

  .hero {
    padding-top: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  .underline-description {
    text-wrap: nowrap;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .text-content {
    text-align: center;
  }

  .hero img {
    display: none;
  }

  .discover-content {
    margin-top: 70px;
  }

  .text-content .title-hero {
    font-size: 48px;
    line-height: 40px;
  }

  .underline-description {
    font-size: 32px !important;
  }

  .text-content div .description {
    font-size: 16px;
  }
}
