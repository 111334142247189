.profile-page-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
}

.profile-informations {
  border-radius: 14px;
  width: 60%;
  height: 100%;
  border: 2px solid var(--dashboard-border-color);
  padding: 10px 20px;
  margin-right: 20px;
}

.profile-password {
  border-radius: 14px;
  width: 40%;
  height: 100%;
  border: 2px solid var(--dashboard-border-color);
  padding: 10px 20px;
}

.profile-informations,
.profile-password {
  background: var(--dashboard-background-color);
}

.profile-informations h2,
.profile-password h2 {
  text-wrap: nowrap;
  font-family: 'Poppins';
  margin: 0;
  padding: 10px 0px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-font-color);
  transition: color 0.3s;
}

.profile-container {
  height: 100%;
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0px 20px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.save-button {
  font-family: 'Inter';
  font-weight: 400;
  line-height: 16px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  background: var(--site-color);
  color: white;
  border: transparent;
  border-radius: 16px;
}

.informations-form {
  width: 100%;
}

.informations-form p {
  font-size: 15px;
  line-height: 18px;
  font-family: 'Inter';
  color: var(--primary-font-color);
  margin: 0;
}

.informations-form input {
  width: 100%;
  height: 55px;
  background: var(--dashboard-even-color);
  border: none;
  border-radius: 16px;
  color: var(--dashboard-navbar-font-color);
  font-family: 'Inter';
  font-size: 15px;
  line-height: 18px;
  padding: 0px 20px;
  margin: 10px 0;
  outline: none;
}

.subscription-container {
  display: flex;
  align-items: center;
  position: relative;
}

.subscription-input {
  width: calc(100% - 150px); /* Adjust this value based on the space you want for the text */
  height: 55px;
  background: var(--dashboard-even-color);
  border: none;
  border-radius: 16px;
  color: var(--dashboard-navbar-font-color);
  font-family: 'Inter';
  font-size: 15px;
  line-height: 18px;
  padding: 0px 20px;
  margin: 10px 0;
  outline: none;
}

.subscription-text {
  font-family: 'Inter';
  font-size: 15px;
  line-height: 18px;
  color: var(--primary-font-color);
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .profile-informations,
  .profile-password {
    width: 100%;
    margin-right: 0;
  }

  .profile-page-content {
    flex-direction: column;
    width: 100%;
  }

  .profile-password {
    margin-top: 20px;
  }
}
