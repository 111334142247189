.landing-page-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.landing-page-dashboard::-webkit-scrollbar {
  width: 6px;
}

.landing-page-dashboard::-webkit-scrollbar-thumb {
  background-color: var(--site-color);
  border-radius: 10px;
}

.landing-page-dashboard::-webkit-scrollbar-track {
  background: transparent;
}

.landing-page-content {
  padding: 0px 20px 20px 20px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.low-caps-content {
  flex: 1;
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  margin-bottom: 20px;
  border-radius: 14px;
  height: 50%;
  overflow: hidden;
}

.trendings-content {
  flex: 1;
  border: 2px solid var(--dashboard-border-color);
  background: var(--dashboard-background-color);
  border-radius: 14px;
  height: 50%;
  overflow: hidden;
}

.trendings-content h2,
.low-caps-content h2 {
  font-family: 'Poppins';
  margin: 0;
  padding: 15px 20px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-font-color);
  transition: color 0.3s;
}

.low-caps-content table,
.trendings-content table {
  border-collapse: collapse;
  width: 100%;
}

.low-caps-content table thead,
.trendings-content table thead {
  font-size: 14px;
  font-family: 'Inter';
  color: var(--dashboard-font-color);
}

.low-caps-content table thead th,
.trendings-content table thead th {
  position: sticky;
  top: 0;
  background: var(--dashboard-background-color);
  z-index: 1;
  padding: 10px;
}

.low-caps-content table thead th::after,
.trendings-content table thead th::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--dashboard-border-color);
}

.table-container {
  padding: 0px 20px;
  flex-grow: 1;
  max-height: 320px;
  overflow-y: auto;
}

.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: var(--site-color);
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.low-caps-content table thead tr th,
.low-caps-content table tbody tr td {
  width: 20%;
  padding: 10px;
  text-align: left;
}

.trendings-content table thead tr th,
.trendings-content table tbody tr td {
  width: 15%;
  padding: 10px;
  text-align: left;
}

.symbol-content {
  display: flex;
  align-items: center;
}

.low-caps-content table tbody tr td img,
.trendings-content table tbody tr td img {
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.low-caps-content table tbody tr td,
.trendings-content table tbody tr td {
  font-size: 14px;
  font-family: 'Inter';
  color: var(--primary-font-color);
}

.low-caps-content table tbody tr:nth-child(even),
.trendings-content table tbody tr:nth-child(even) {
  background: var(--dashboard-even-color);
}

.low-caps-content table thead tr th,
.trendings-content table thead tr th {
  font-weight: 400;
}

.positive-change {
  color: rgba(0, 188, 120, 1) !important;
}
.negative-change {
  color: rgba(255, 80, 101, 1) !important;
}

.footer-mobile {
  display: none;
  width: 100%;
  padding-top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  z-index: 1;
  background: var(--primary-background-color);
}

.positive-change div,
.negative-change div {
  font-size: 17px;
}

.positive-change,
.negative-change {
  display: flex;
  align-items: center;
}

.sparklines {
  padding: 0px 10px !important;
}

@media screen and (max-width: 600px) {
  .footer-mobile {
    display: flex;
  }
}
